import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "./MainLayout.scss";
import Sidebar from "../../Sidebar/Sidebar";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

const MainLayout = () => {
  const [active, setActive] = useState(true);
  const body = document.querySelector("body");
  const sidebarHandle = () => {
    if (window.innerWidth < 1280) {
      setActive(!active);
      body?.classList.toggle("overflow-hidden");
    }
  };
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1279) {
        setActive(true);
        body?.classList.remove("overflow-hidden");
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <section className="main_handle">
        <div className="main_handle_left">
          <Sidebar
            className={active ? "" : "active"}
            menuItemClick={sidebarHandle}
          />
        </div>
        <div className="main_handle_right">
          {active ? (
            ""
          ) : (
            <div className="sidebar-backdrop" onClick={sidebarHandle}></div>
          )}
          <Header
            toggleClassName={active ? "" : "active"}
            toggleClick={sidebarHandle}
          />
          <div className="main_handle_right_content">
            <Outlet />
          </div>
          <Footer />
        </div>
      </section>
    </>
  );
};
export default MainLayout;
