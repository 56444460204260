import { memo } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import "./Sidebar.scss";

const Sidebar = ({ className, menuItemClick }) => {
  return (
    <>
      <div className={`sidebar ${className}`}>
        <div className="sidebar_logo">
          <Link to="#">
            {/* <img src={Logo} alt="" /> */}
          </Link>
        </div>
        <ul className="sidebar_menu">
          <li className="sidebar_menu_item">
            <NavLink
              className="sidebar_menu_item_link"
              to="/"
              onClick={menuItemClick}
            >
              <span className="sidebar_menu_item_title">Dashboard</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};
export default memo(Sidebar);
