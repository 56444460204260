import React from 'react'
import { Col, Row } from "react-bootstrap"
import CommonButton from "../../../Common/CommonButton/CommonButton"
// import { Container } from 'react-bootstrap'
import ExchangeTable from '../../../Common/ExchangeTable/ExchangeTable'
import "./History.scss"
// import logo from "../../../../assets/images/logo.png"

const History = () => {
    return (
        <>
            <div className="history_page">
                <Row className="pb-5 align-items-center">
                    <Col xs={5}><h1>History</h1></Col>
                    <Col className="text-end"><CommonButton title="Action Button"/></Col>
                </Row>

                {/* <Container> */}
                    {/* <div className='siteLogo'>
                        <img src={logo} alt='Logo' />
                    </div> */}
                    {/* <div className='pt-60'> */}
                        <ExchangeTable />
                    {/* </div> */}
                {/* </Container> */}
            </div>
        </>
    )
}

export default History