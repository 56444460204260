import React from 'react'
import "./ExchangeTable.scss"
import { Table } from 'react-bootstrap'
import CommonPagination from '../CommonPagination/CommonPagination'

const ExchangeTable = () => {
    const offerdata = [
        {
            exchange: 'Buy',
            source: 'hello',
            pool: 'Jai_1233',
            amount: '300',
            profit: '300',
            date: '08/08/2023, 10:55:00 AM',
            gas: '0.001',
        },
        {
            exchange: 'Buy',
            source: 'hello',
            pool: 'Jai_1233',
            amount: '300',
            profit: '300',
            date: '08/08/2023, 10:55:00 AM',
            gas: '0.001',
        },
        {
            exchange: 'Buy',
            source: 'hello',
            pool: 'Jai_1233',
            amount: '300',
            profit: '300',
            date: '08/08/2023, 10:55:00 AM',
            gas: '0.001',
        },
        {
            exchange: 'Buy',
            source: 'hello',
            pool: 'Jai_1233',
            amount: '300',
            profit: '300',
            date: '08/08/2023, 10:55:00 AM',
            gas: '0.001',
        },
        {
            exchange: 'Buy',
            source: 'hello',
            pool: 'Jai_1233',
            amount: '300',
            profit: '300',
            date: '08/08/2023, 10:55:00 AM',
            gas: '0.001',
        },
        {
            exchange: 'Buy',
            source: 'hello',
            pool: 'Jai_1233',
            amount: '300',
            profit: '300',
            date: '08/08/2023, 10:55:00 AM',
            gas: '0.001',
        },
        {
            exchange: 'Buy',
            source: 'hello',
            pool: 'Jai_1233',
            amount: '300',
            profit: '300',
            date: '08/08/2023, 10:55:00 AM',
            gas: '0.001',
        },
    ];
    return (
        <>
            <div className='commonCard'>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Exchange</th>
                            <th>Loaned Source</th>
                            <th>Pool</th>
                            <th>Amount</th>
                            <th>Profit</th>
                            <th>Date</th>
                            <th>Gas Used</th>
                        </tr>
                    </thead>
                    <tbody>
                        {offerdata.map((data, i) => {
                            return (
                                <tr key={i}>
                                    <td>{data.exchange}</td>
                                    <td>{data.source}</td>
                                    <td>{data.pool}</td>
                                    <td>{data.amount}</td>
                                    <td>{data.profit}</td>
                                    <td>{data.date}</td>
                                    <td>{data.gas}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>

                <div className='mt-4 mt-md-5 d-flex justify-content-center'>
                    <CommonPagination />
                </div>
            </div>
        </>
    )
}

export default ExchangeTable
