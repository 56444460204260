import Pagination from "react-bootstrap/Pagination";
import "./CommonPagination.scss";

const CommonPagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageClick = (page) => {
    if (page !== currentPage) {
      onPageChange(page); // Call the provided onPageChange function
    }
  };

  const paginationItems = [];

  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage} // Highlight current page
        onClick={() => handlePageClick(number)} // Handle click
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <div className="commonPagination">
      <Pagination>
        <Pagination.Prev
          className="Prev_btn"
          onClick={() => handlePageClick(currentPage > 1 ? currentPage - 1 : 1)}
          disabled={currentPage === 1}
        />
        {paginationItems}
        <Pagination.Next
          className="Next_btn"
          onClick={() =>
            handlePageClick(
              currentPage < totalPages ? currentPage + 1 : totalPages
            )
          }
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </div>
  );
};

export default CommonPagination;
