import { Modal } from "react-bootstrap";
import './CommonModal.scss';

const CommonModal = ({ children, className, title, show, onHide }) => {
    return (
        <>
            <Modal show={show} onHide={onHide} className={`common_modal ${className}`}>
                <Modal.Header closeButton>
                    {title && <><Modal.Title>{title}</Modal.Title></>}
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CommonModal