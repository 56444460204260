import { APIURL } from "../constants";
import {
  callApiGetMethod,
  callApiPostMethod,
} from "../services/axios.services";

export const getBatchList = async (dispatch,batchId,limit,page=1) => {
  try {
    const result = await dispatch(
      callApiGetMethod("BATCH_LIST", {batchId:batchId,limit,page}, false,false)
    );
    if (result?.status === 200) {
      return result;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getBatchListWithId = async (dispatch) => {
  try {
    const result = await dispatch(
      callApiGetMethod("BATCH_LIST_WITH_ID", {}, false, false)
    );
    if (result?.status === 200) {
      return result;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getBatchListDeposit = async (dispatch,msgValue,batchId,amount) => {
  try {
    const result = await dispatch(
      callApiPostMethod("DEPOSIT_BATCH_LIST", {msgValue, batchId,amount }, {}, false)
    );
    if (result?.status === 200) {
      return result;
    }
  } catch (error) {
    console.error(error);
  }
};
export const updateStatus = async (dispatch,addresses,batchId,) => {
  try {
    const result = await dispatch(
      callApiPostMethod("UPDATE_STATUS", {addresses, batchId }, {}, false)
    );
    if (result?.status === 200) {
      return result;
    }
  } catch (error) {
    console.error(error);
  }
};
