import React from 'react';
import "./Footer.scss";

const Footer = () => {
    return (
        <>
            <div className='footer_sec'>
                <p>© {new Date().getFullYear} Ethanet. All Rights Reserved.</p>
            </div>
        </>
    );
};

export default Footer;