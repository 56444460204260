import { Table } from "react-bootstrap";
import "./SelectedTokenTable.scss";

const SelectedTokenTable = ({ batchData, currentPage, itemsPerPage }) => {
  return (
    <>
      <div className="selected_token_table">
        <Table responsive className="border">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Wallet Address</th>
              <th>Percentage Share</th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {batchData?.map((data, i) => {
              // Calculate serial number based on page
              const serialNumber = i + 1 + (currentPage - 1) * itemsPerPage;
              return (
                <tr key={i}>
                  <td>{serialNumber}</td>
                  <td>{data.walletAddress}</td>
                  <td>{(data.percent)+"%"}</td>
                  {/* <td></td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default SelectedTokenTable;
