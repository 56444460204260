export function DashboardIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame">
        <g id="Group">
          <path id="Vector" d="M20.6483 22.5H14.6017C14.1108 22.4994 13.6402 22.3041 13.293 21.957C12.9459 21.6098 12.7506 21.1392 12.75 20.6483V13.1017C12.7506 12.6108 12.9459 12.1402 13.293 11.793C13.6402 11.4459 14.1108 11.2506 14.6017 11.25H20.6483C21.1392 11.2506 21.6098 11.4459 21.957 11.793C22.3041 12.1402 22.4994 12.6108 22.5 13.1017V20.6483C22.4994 21.1392 22.3041 21.6098 21.957 21.957C21.6098 22.3041 21.1392 22.4994 20.6483 22.5ZM20.6483 9.75H14.6017C14.1108 9.7494 13.6402 9.55412 13.293 9.20698C12.9459 8.85984 12.7506 8.38918 12.75 7.89825V3.35175C12.7506 2.86082 12.9459 2.39016 13.293 2.04302C13.6402 1.69588 14.1108 1.5006 14.6017 1.5H20.6483C21.1392 1.5006 21.6098 1.69588 21.957 2.04302C22.3041 2.39016 22.4994 2.86082 22.5 3.35175V7.89825C22.4994 8.38918 22.3041 8.85984 21.957 9.20698C21.6098 9.55412 21.1392 9.7494 20.6483 9.75ZM9.39825 12.75H3.35175C2.86082 12.7494 2.39016 12.5541 2.04302 12.207C1.69588 11.8598 1.5006 11.3892 1.5 10.8983V3.35175C1.5006 2.86082 1.69588 2.39016 2.04302 2.04302C2.39016 1.69588 2.86082 1.5006 3.35175 1.5H9.39825C9.88918 1.5006 10.3598 1.69588 10.707 2.04302C11.0541 2.39016 11.2494 2.86082 11.25 3.35175V10.8983C11.2494 11.3892 11.0541 11.8598 10.707 12.207C10.3598 12.5541 9.88918 12.7494 9.39825 12.75ZM9.39825 22.5H3.35175C2.86082 22.4994 2.39016 22.3041 2.04302 21.957C1.69588 21.6098 1.5006 21.1392 1.5 20.6483V16.1017C1.5006 15.6108 1.69588 15.1402 2.04302 14.793C2.39016 14.4459 2.86082 14.2506 3.35175 14.25H9.39825C9.88918 14.2506 10.3598 14.4459 10.707 14.793C11.0541 15.1402 11.2494 15.6108 11.25 16.1017V20.6483C11.2494 21.1392 11.0541 21.6098 10.707 21.957C10.3598 22.3041 9.88918 22.4994 9.39825 22.5Z" fill="white" />
        </g>
      </g>
    </svg>
  )
}
export function HandIcon() {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path id="Vector" d="M31.946 19.3264L22.3074 29.4143C21.449 30.2727 20.4299 30.9537 19.3083 31.4183C18.1867 31.8828 16.9846 32.122 15.7706 32.122H13.9508C9.27135 32.122 5.47728 28.3283 5.47728 23.6485C5.47728 21.3934 4.8835 19.1787 3.75627 17.226L0.874002 12.2337C0.432739 11.4695 0.537117 10.5338 1.07749 9.88872C1.98448 8.80354 3.71275 8.93487 4.43222 10.1795C8.62678 17.4449 3.76949 9.03136 8.53672 17.2889C5.25229 5.03156 5.26654 5.3911 5.38228 4.72632C5.38228 4.65969 5.46605 4.32768 5.58192 4.09594C6.52786 2.15288 9.34722 2.45742 9.89569 4.50228L12.9237 15.8041C12.9237 1.80669 12.9135 2.51442 12.9423 2.29918C13.1024 1.09954 14.149 0.285445 15.1159 0.285445C16.4547 0.220161 17.5456 1.28422 17.5456 2.59318V16.2021C21.4092 2.72542 21.0886 3.67041 21.374 3.23511C21.9751 2.28262 23.3348 1.88289 24.4187 2.49047C25.2783 2.92198 25.8216 4.02995 25.5325 5.11083L22.1675 17.6683V20.3984C22.1675 21.3138 23.2735 21.7721 23.9206 21.1251C27.0273 17.5285 26.7497 17.8406 26.8985 17.7017C28.5061 16.2118 30.6921 15.9927 31.4151 16.3542C32.5576 16.8454 32.9044 18.368 31.946 19.3264Z" fill="#F9CFAC" />
        <path id="Vector_2" d="M32.378 17.3567L21.3612 28.7914C19.9803 30.0064 18.2682 30.7816 16.444 31.0176C10.7099 31.7631 6.50439 27.5149 6.50439 22.6212C6.50439 20.3661 5.91061 18.1514 4.78338 16.1987C0.895207 9.58604 1.12091 9.9737 1.07751 9.88852C1.33021 9.58623 1.66455 9.36304 2.04062 9.24557C2.4167 9.12811 2.81863 9.12133 3.19845 9.22604C3.64376 9.34827 3.89886 9.55157 4.18831 9.84101C6.36984 13.4949 8.54316 17.2887 8.53674 17.2887C9.08733 17.2887 9.48808 16.7664 9.34557 16.2346C6.27907 4.79063 6.4237 5.50189 6.44469 5.00792C6.46235 4.28524 6.88474 3.51563 7.57385 3.11359C7.86297 2.9438 8.18368 2.85514 8.21 2.85489C9.00343 3.04811 9.66911 3.65794 9.89571 4.50208L12.9237 15.8039C12.9237 1.30963 12.9082 2.48719 12.9596 2.18407C13.022 1.83711 13.1678 1.51056 13.3352 1.27638C13.5339 0.986422 13.8011 0.775034 13.8076 0.775034C15.3743 -0.433271 17.5456 0.71251 17.5456 2.59298V16.2019C21.2938 3.12784 21.0928 3.8156 21.1276 3.72534C21.6749 2.16578 23.7642 1.61975 25.0067 2.95439C25.0858 3.04381 25.3612 1.8608 21.1404 17.8606V20.5908C21.1404 21.3762 22.017 22.1346 23.0296 21.7405C23.7099 21.4755 24.0911 20.9275 24.6126 20.3237C26.959 17.6078 26.6786 17.9272 26.8416 17.7558C27.5632 17.0592 28.4603 16.5716 29.4373 16.345C29.8676 16.2466 30.5477 16.1595 30.9639 16.2231C31.2322 16.2644 31.4879 16.3654 31.712 16.5188C31.9361 16.6721 32.1229 16.8738 32.2586 17.1089C32.3316 17.2312 32.3749 17.3503 32.378 17.3567Z" fill="#FFE4CC" />
        <path id="Vector_3" d="M14.9779 29.4899C14.8417 29.4899 14.7111 29.4358 14.6148 29.3395C14.5185 29.2431 14.4644 29.1125 14.4644 28.9763C14.4644 24.8742 17.526 21.373 21.5861 20.8322C21.8682 20.7952 22.1256 20.9923 22.163 21.2734C22.1719 21.3402 22.1675 21.4081 22.1502 21.4733C22.1328 21.5385 22.1028 21.5996 22.0618 21.6532C22.0209 21.7067 21.9697 21.7517 21.9114 21.7855C21.853 21.8193 21.7886 21.8413 21.7218 21.8502C18.1699 22.3233 15.4914 25.3869 15.4914 28.9763C15.4914 29.0438 15.4781 29.1105 15.4523 29.1728C15.4265 29.2351 15.3887 29.2918 15.341 29.3395C15.2933 29.3871 15.2367 29.425 15.1744 29.4508C15.1121 29.4766 15.0453 29.4899 14.9779 29.4899Z" fill="#F9CFAC" />
      </g>
    </svg>
  )
}
export function CrossIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.2828 12.0001L18.7343 6.54851C19.089 6.1939 19.089 5.62053 18.7343 5.26596C18.3797 4.91135 17.8063 4.91135 17.4517 5.26596L12.0001 10.7175L6.54864 5.26596C6.19386 4.91135 5.6207 4.91135 5.26609 5.26596C4.9113 5.62057 4.9113 6.1939 5.26609 6.54851L10.7175 12.0001L5.26613 17.4517C4.91135 17.8063 4.91135 18.3797 5.26613 18.7343C5.35024 18.8186 5.4502 18.8855 5.56025 18.9311C5.6703 18.9767 5.78828 19.0001 5.90741 19C6.13956 19 6.3718 18.9111 6.54868 18.7343L12.0001 13.2827L17.4517 18.7343C17.5359 18.8186 17.6358 18.8855 17.7459 18.9311C17.8559 18.9767 17.9739 19.0001 18.093 19C18.3252 19 18.5574 18.9111 18.7343 18.7343C19.089 18.3796 19.089 17.8063 18.7343 17.4517L13.2828 12.0001Z" fill="white" />
    </svg>
  )
}
export function DeleteIcon() {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.76757 1.46152C4.7676 1.31633 4.82527 1.1771 4.9279 1.07441C5.03054 0.971723 5.16975 0.913985 5.31493 0.913883L10.7882 0.913574C10.9335 0.913911 11.0726 0.971809 11.1752 1.07458C11.2778 1.17735 11.3355 1.31661 11.3356 1.46183V2.81547H4.76757V1.46152ZM13.7667 19.8654C13.7528 20.0789 13.6578 20.2789 13.5012 20.4246C13.3445 20.5703 13.1382 20.6506 12.9243 20.6491H3.11599C2.90216 20.6485 2.69646 20.5671 2.5402 20.4211C2.38394 20.2751 2.28869 20.0754 2.27359 19.8621L1.43427 7.58426H14.6625L13.7669 19.8653L13.7667 19.8654ZM15.9473 6.47226H0.15625V5.20021C0.156485 4.86284 0.29059 4.53935 0.529123 4.30077C0.767655 4.06219 1.09112 3.92801 1.42849 3.9277L14.6749 3.92728C15.0122 3.9278 15.3356 4.06209 15.574 4.3007C15.8125 4.53931 15.9466 4.86276 15.9469 5.2001V6.47214L15.9473 6.47226ZM5.50917 17.7222C5.50917 17.7952 5.52355 17.8675 5.55149 17.9349C5.57942 18.0024 5.62037 18.0637 5.672 18.1153C5.72362 18.1669 5.7849 18.2079 5.85235 18.2358C5.9198 18.2637 5.99209 18.2781 6.06509 18.2781C6.1381 18.2781 6.21039 18.2637 6.27783 18.2358C6.34528 18.2079 6.40657 18.1669 6.45819 18.1153C6.50981 18.0637 6.55076 18.0024 6.5787 17.9349C6.60664 17.8675 6.62101 17.7952 6.62101 17.7222V9.92621C6.61985 9.77959 6.5608 9.63938 6.45673 9.5361C6.35266 9.43282 6.212 9.37485 6.06538 9.37479C5.91876 9.37474 5.77806 9.43262 5.67392 9.53583C5.56978 9.63904 5.51063 9.77921 5.50936 9.92582V17.7222H5.50917ZM9.47564 17.7222C9.47564 17.8696 9.53422 18.0111 9.63849 18.1153C9.74276 18.2196 9.88418 18.2782 10.0316 18.2782C10.1791 18.2782 10.3205 18.2196 10.4248 18.1153C10.5291 18.0111 10.5876 17.8696 10.5876 17.7222V9.92621C10.5865 9.77957 10.5274 9.63934 10.4233 9.53605C10.3192 9.43275 10.1786 9.37477 10.0319 9.37472C9.88529 9.37467 9.74457 9.43256 9.64041 9.53578C9.53626 9.639 9.4771 9.77919 9.47583 9.92582L9.47564 17.7222Z"
        fill="#FC0005"
      />
    </svg>
  )
}

export const InfoIcon = () => (
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 8H12.01M12 11V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export function LeftArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
    >
      <path
        d="M7.35319 12.5078C7.95961 12.5078 8.38573 12.1016 8.38573 11.5547C8.38573 11.2578 8.25462 11.0234 8.06614 10.8359L6.21412 9.0625L4.13265 7.24219L5.97647 7.34375H15.5807C16.2199 7.34375 16.6542 6.9375 16.6542 6.34375C16.6542 5.75 16.2199 5.34375 15.5807 5.34375H5.97647L4.13265 5.4375L6.21412 3.625L8.06614 1.84375C8.26281 1.65625 8.38573 1.42188 8.38573 1.125C8.38573 0.585938 7.95961 0.171875 7.35319 0.171875C7.09096 0.171875 6.83692 0.273438 6.59928 0.492188L1.2399 5.60156C1.03503 5.78906 0.912109 6.07031 0.912109 6.34375C0.912109 6.61719 1.03503 6.89062 1.2399 7.08594L6.61566 12.1953C6.83692 12.4062 7.09096 12.5078 7.35319 12.5078Z"
        fill="white"
      />
    </svg>
  );
}