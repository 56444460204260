// FullPageLoader.js

import { Spinner } from "react-bootstrap";
import "./FullPageLoader.scss";

const FullPageLoader = () => {
  return (
    <div className="full-page-loader-overlay">
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default FullPageLoader;
