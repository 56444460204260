import { useEffect, useState } from "react";
import {
  setIsLoggedIn,
  setUserWalletAddress,
} from "../redux/userData/userData";
// import {
//   setIsLoggedIn,
//   setUserWalletAddress,
// } from "../Redux/reducers/user/user";

// interface UseWalletAccountProps {
//   wallet: any;
//   walletType: string;
//   dispatch: (action: any) => void;
// //   toaster: any;
// }

const useWalletAccount = ({
  wallet,
  walletType,
  dispatch,
  //   toaster,
}) => {
  const [disconnectMessage, setDisconnectMessage] = useState("");

  const handleAccountsChanged = (accounts) => {
    if (!accounts?.length) {
      dispatch(setUserWalletAddress(""));
      //   if (typeof toaster === "function") {
      //   } else {
      //     toaster.success(`${walletType} disconnected successfully.`);
      //   }
      setDisconnectMessage(`${walletType} disconnected successfully.`);
      setTimeout(() => {
        setDisconnectMessage("");
      }, 5000);
    } else {
      const address = accounts[0];
      dispatch(setUserWalletAddress(address));
      dispatch(setIsLoggedIn(true));
      setDisconnectMessage("");
    }
  };

  useEffect(() => {
    if (wallet && wallet.on) {
      const onAccountsChanged = (accounts) => {
        handleAccountsChanged(accounts);
      };

      wallet.on("accountsChanged", onAccountsChanged);

      return () => {
        wallet.off("accountsChanged", onAccountsChanged);
      };
    }
  }, [wallet]);

  // Log disconnectMessage whenever it changes
  useEffect(() => {}, [disconnectMessage]);

  return {
    disconnectMessage,
  };
};

export default useWalletAccount;
