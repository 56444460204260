// /** @format */

import { useDispatch } from "react-redux";
import { isAddress as validateAddressUsingValidator } from "web3-validator";
import toast from "react-hot-toast";
import {
  callGetMethod,
  callSendMethod,
  callWeb3,
  createInstance,
} from "./contract.service";
import { getError } from "./comman.service";

/**CALL COONTRACT'S GET METHODS */
export const callContractGetMethod = (method, data = [], showError = true) => {
  return async (dispatch = useDispatch()) => {
    try {
      /**SHOW LOADING */
      const result = await callGetMethod(method, data);
      return result;
    } catch (error) {
      return showError ? toast.error(getError(error)) : null;
    }
  };
};

/**CALL COONTRACT'S SEND METHODS */
export function callContractSendMethod(
  // provider,
  method,
  data,
  walletAddress,
  value = ""
) {
  return async (dispatch = useDispatch(), getState) => {
    try {
      /**CREATE INSTANCE WITH WALLET */
      const contractInstance = await createInstance();
      if (contractInstance) {
        /**CALL SEND METHOD */
        const result = await callSendMethod(method, data, walletAddress, value);
        return result;
      } else {
        /**IF ANY ERROR IN CREATING INSTANCE */
        return toast.error(
          "Some error occurred during contract interaction. Please reload the page."
        );
      }
    } catch (error) {
      toast.error(getError(error));
      throw error;
    }
  };
}

export const validateAddress = (address, type) => {
  return async () => {
    try {
      let isAddress = validateAddressUsingValidator(address);
      if (isAddress) {
        let web3 = await callWeb3();
        let check = await web3.eth.getCode(address);
        if (
          (check !== "0x" && type === "wallet") ||
          (check === "0x" && type === "contract")
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
