import BigValue from "bignumber.js";
// import CryptoJS from 'crypto-js'
import JSBI from "jsbi";
import Web3 from "web3";

/**CUTMIZE ADDRESS FOR SHOW */
export const custmizeAddress = (address) => {
  const firstFive = address?.substring(0, 5);
  const lastFour = address?.substring(address.length - 4);
  return firstFive + "..." + lastFour;
};
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function numberToString(arg) {
  if (typeof arg === "string") {
    if (!arg.match(/^-?[0-9.]+$/)) {
      throw new Error(
        "while converting number to string, invalid number value '" +
          arg +
          "', should be a number matching (^-?[0-9.]+)."
      );
    }
    return arg;
  } else if (typeof arg === "number") {
    return String(arg);
  } else if (
    typeof arg === "object" &&
    arg.toString &&
    (arg.toTwos || arg.dividedToIntegerBy)
  ) {
    if (arg.toPrecision) {
      return String(arg.toPrecision());
    } else {
      // eslint-disable-line
      return arg.toString(10);
    }
  }
  throw new Error(
    "while converting number to string, invalid number value '" +
      arg +
      "' type " +
      typeof arg +
      "."
  );
}

// Function to convert into wei
function toWei(input, unit) {
  var ether = numberToString(input); // eslint-disable-line
  const base = unit;
  const baseLength = base.length - 1 || 1;
  if (ether === ".") {
    throw new Error(
      "[ethjs-unit] while converting number " + input + " to wei, invalid value"
    );
  }

  // Is it negative?
  const negative = ether.substring(0, 1) === "-";

  if (negative) {
    ether = ether.substring(1);
  }
  // Split it into a whole and fractional part
  var comps = ether.split("."); // eslint-disable-line
  if (comps.length > 2) {
    throw new Error(
      "[ethjs-unit] while converting number " +
        input +
        " to wei,  too many decimal points"
    );
  }
  let whole = comps[0],
    fraction = comps[1]; // eslint-disable-line
  if (!whole) {
    whole = "0";
  }
  if (!fraction) {
    fraction = "0";
  }
  if (fraction.length > baseLength) {
    throw new Error(
      "[ethjs-unit] while converting number " +
        input +
        " to wei, too many decimal places"
    );
  }

  while (fraction.length < baseLength) {
    fraction += "0";
  }

  if (!parseInt(whole)) {
    return fraction.replace(/^0*(?=[1-9])/g, "");
  }

  if (negative) {
    return "-" + whole + fraction;
  }

  return whole + fraction;
}

function fromWei(value, numberOfDecimals) {
  const numberOfZerosInDenomination = numberOfDecimals.length - 1;
  if (numberOfZerosInDenomination <= 0) return value;
  const zeroPaddedValue = value?.padStart(numberOfZerosInDenomination, "0");
  const integer = zeroPaddedValue?.slice(0, -numberOfZerosInDenomination);
  const fraction = zeroPaddedValue
    ?.slice(-numberOfZerosInDenomination)
    .replace(/\.?0+$/, "");
  if (integer === "") return `0.${fraction}`;
  if (fraction === "") return integer;
  return `${integer}.${fraction}`;
}

export const handleErrorMessage = (error, customError) => {
  if (
    error?.message?.includes("gas less") ||
    error?.message?.includes("cap less")
  ) {
    return "Please try once with more gas fee.";
  } else if (error?.message?.includes("rejected") || error.code === 4001) {
    return "User rejected the request.";
  } else if (error?.message?.includes("zeroAmount")) {
    return "Either you haven't staked or you are trying with zero amount";
  } else if (error?.message?.includes("AmountGreaterThanStakedAmount")) {
    return "Amount you want to withdraw is greater than staked one";
  } else if (error?.message?.includes("LockinPeriodNotEnded")) {
    return "You have to wait till lockin period";
  } else if (error?.message?.includes("notEnoughRewardBalance")) {
    return "Reward tank is empty";
  } else if (error?.message?.includes("LockinPeriodOver")) {
    return "Please try with simple withdraw";
  } else if (error?.message?.includes("amountGreaterThanStakedAmount")) {
    return "Amount you want to withdraw is greater than staked one";
  } else if (error?.message?.includes("noStakesFound")) {
    return "Staked Amount is not found";
  } else if (error?.message?.includes("zeroAmount")) {
    return "You can't withdraw zero amount";
  } else if (error?.message?.includes("zeroAmount")) {
    return "Reward is not generated yet";
  } else if (error?.message?.includes("zeroAmount")) {
    return "Reward tank is empty";
  } else if (error?.message?.includes("noStakesFound")) {
    return "Staked Amount is not found";
  } else if (error?.message?.includes("zeroAmount")) {
    return "Reward is not generated yet";
  } else if (error?.message?.includes("zeroAmount")) {
    return "Reward tank is empty";
  } else if (error?.message?.includes("ERC20: insufficient allowance")) {
    return "Insufficient balance in rewards pool";
  } else if (error?.message?.includes("rateShouldBeInBetween0to30")) {
    return "Rate Should Be In Between 0 to 30";
  } else if (
    error?.message?.includes("execution reverted") ||
    error.code === -32603
  ) {
    return "Something went wrong. Please try again later.";
  } else if (error?.message?.includes("An internal error was received")) {
    window.location.reload();
  } else if (error?.message?.includes("No connection established")) {
    return "Services down, please try after sometime";
  } else if (error?.message?.includes("OTP Limit Reached")) {
    return "OTP Limit Reached, Try again after 10 minutes.";
  } else if (error?.code === 4001) {
    return "User denied transaction";
  } else if (customError) {
    return customError;
  } else {
    return "Something went wrong.";
  }
  return error;
};

export function divideByPowerOfTenToString(number, power) {
  let etherAmount = "1";
  if (power === 18) etherAmount = Web3.utils.fromWei(String(number), "ether");
  else if (power === 6)
    etherAmount = Web3.utils.fromWei(String(number), "mwei");
  return etherAmount;
}

export const intToSuffixes = (num, fixed = 2) => {
  const absNum = Math.abs(num);
  const suffixes = ["", "K", "M", "B", "T", "Q"];

  // Determine the appropriate suffix index
  let suffixIndex = Math.floor(Math.log10(absNum) / 3);
  if (suffixIndex > 5) {
    suffixIndex = 5;
  }
  // Adjust the number by dividing by 1000 raised to the power of the suffix index
  const adjustedNum = num > 1 ? num / Math.pow(1000, suffixIndex) : num;

  // Round to two decimal places
  const roundedNum = fixedToDecimal(toFixed(adjustedNum), 2);

  // Concatenate the adjusted number with the suffix
  return `${roundedNum}${suffixes[suffixIndex] ? suffixes[suffixIndex] : ""}`;
};

// eslint-disable-next-line import/no-unused-modules
export const divideBigNumber = (value, decimal, suffixes = false) => {
  if (!decimal || decimal === 0 || !value) {
    return "0";
  } else {
    const decimalBigN = JSBI.BigInt(decimal);
    const convertedDecimal = JSBI.exponentiate(JSBI.BigInt(10), decimalBigN);
    const x = new BigValue(value?.toString());
    const y = new BigValue(String(convertedDecimal));
    const z = x.dividedBy(y);
    return suffixes
      ? intToSuffixes(parseFloat(z.toString()))
      : fixedToDecimal(z.toString(), 5);
  }
};

/** Divide with Decimal*/
export const divideWithDecimal = (value, decimal) => {
  const decimalBigN = JSBI.BigInt(decimal);
  const convertedDecimal = JSBI.exponentiate(JSBI.BigInt(10), decimalBigN);
  let result = fromWei(String(value), String(convertedDecimal));
  // result = parseFloat(result)
  return result;
  // return fixedToDecimal(result)
};

/**CONVERT NUMBER WITH DECIMALS FOR CONTRACT CALL */
export const convertWithDecimal = (value, decimal) => {
  const decimalBigN = JSBI.BigInt(decimal);
  const convertedDecimal = JSBI.exponentiate(JSBI.BigInt(10), decimalBigN);
  return toWei(value, String(convertedDecimal));
};

/** Multiply with big numbers */
// eslint-disable-next-line import/no-unused-modules
export const multiplyTwoBigDigits = (valueOne, valueTwo) => {
  const a = JSBI.BigInt(valueOne);
  const b = JSBI.BigInt(valueTwo);
  const result = JSBI.multiply(a, b);
  return String(result);
};

// eslint-disable-next-line import/no-unused-modules
export const multiplyBigDigitsWithDecimals = (valueOne, valueTwo) => {
  let a;
  let b;
  let decimalLengthA = 0;
  let decimalLengthB = 0;
  if (valueOne.includes(".")) {
    a = convertWithDecimal(valueOne, valueOne.split(".")[1].length);
    decimalLengthA = valueOne.split(".")[1].length;
  } else {
    a = valueOne;
  }
  if (valueTwo.includes(".")) {
    b = convertWithDecimal(valueTwo, valueTwo.split(".")[1].length);
    decimalLengthB = valueTwo.split(".")[1].length;
  } else {
    b = valueTwo;
  }
  const decimalLength = decimalLengthA + decimalLengthB;
  let result = multiplyTwoBigDigits(a, b);

  if (
    result.substring(0, result.length - decimalLength).length &&
    result.substring(result.length - decimalLength).length
  ) {
    result =
      result.substring(0, result.length - decimalLength) +
      "." +
      result.substring(result.length - decimalLength);
  } else if (!result.substring(0, result.length - decimalLength).length) {
    // eslint-disable-next-line
    result = "0" + "." + result.substring(result.length - decimalLength);
  }
  return result;
};

/**CHECK STRING IS NUMBER */
// eslint-disable-next-line import/no-unused-modules
export const isInt = (n) => {
  return n % 1 === 0;
};

/**REMOVE e FORM BIG NUMBER */
// eslint-disable-next-line import/no-unused-modules
export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    // eslint-disable-next-line no-var
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

/**GET ERROR MESSAGE FORM ERROR OBJECT */
export const getError = (error) => {
  const errorMsg = error.message ? error.message : "Something went wrong";
  if (errorMsg == "Returned error: Internal JSON-RPC error.") {
    return "Insufficent Balance For Transaction";
  }

  if (
    error?.message?.split(":")[0]?.replace(/\s/g, "") ==
    "TransactionhasbeenrevertedbytheEVM"
  ) {
    let msg = error?.message?.split(":")[0];

    return msg;
  } else if (errorMsg.indexOf("execution reverted") > -1) {
    let msg = errorMsg;

    msg =
      msg.indexOf("execution reverted:") > -1
        ? msg.split("execution reverted:")[1].split("{")[0].split('"')[0]
        : msg;
    return msg;
  } else if (errorMsg.indexOf("INVALID_ARGUMENT") > -1) {
    return errorMsg.split("(")[0];
  } else if (errorMsg.indexOf("MetaMask Tx Signature") > -1) {
    const msg = errorMsg.replace("MetaMask Tx Signature:", "");
    return msg;
  } else {
    const err = errorMsg.split("*")[0].split(":")[1];

    if (err?.trim() === "insufficient funds for gas") {
      return err;
    } else {
      return errorMsg;
    }
  }
};

/**CREATE URL FOR API CALL WITH PARAMS */
export const formatUrl = (url, params) => {
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : ``;
  return `${url}${params}`;
};

/**ALLOW ONLY STRING */
export const allowOnlyString = (inputString) => {
  const res = /^[a-zA-Z]+$/.test(inputString);
  return res;
};

/**SHOW VALUE WITH ONLY SELECTED DECIMALS */
// eslint-disable-next-line import/no-unused-modules
export const fixedToDecimal = (value, decimals = 5) => {
  value =
    value && parseFloat(value) !== 0
      ? decimals === 2
        ? value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        : value.toString().match(/^-?\d+(?:\.\d{0,5})?/)[0]
      : 0;
  return value;
};
export const cryptoDecimals = (inValue) => {
  const value = Number(inValue);
  if (value === 0) {
    return 0.0;
  } else if ((value > 0 && value <= 9) || (value < 0 && value >= -9)) {
    return toCustomFixed(value, 5);
  } else if ((value > 9 && value <= 99) || (value < -9 && value >= -99)) {
    return toCustomFixed(value, 4);
  } else if ((value > 99 && value <= 999) || (value < -99 && value >= -999)) {
    return toCustomFixed(value, 3);
  } else if (
    (value > 999 && value <= 9999) ||
    (value < -999 && value >= -9999)
  ) {
    return toCustomFixed(value, 2);
  } else if (value > 9999 || value < -9999) {
    return toCustomFixed(value, 0);
  }
};
const toCustomFixed = (num, fixed) => {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
};

export const allowOnlyNumberWithDecimalsInput = (value, decimals) => {
  let re;
  let decimalValue = Number(decimals);

  re = new RegExp(
    "^(\\d{0,16}|\\d{0,16}\\.\\d{0," + decimalValue + "})$",
    "gm"
  );
  if (re?.test(value)) {
    return true;
  } else {
    return false;
  }
};

function isPrime(number) {
  if (number <= 1) {
    return false;
  }

  if (number <= 3) {
    return true;
  }

  if (number % 2 === 0 || number % 3 === 0) {
    return false;
  }

  for (let i = 5; i * i <= number; i += 6) {
    if (number % i === 0 || number % (i + 2) === 0) {
      return false;
    }
  }

  return true;
}

function getPrimeNumbersInRange(start, end) {
  const primeNumbers = [];

  for (let number = start; number <= end; number++) {
    if (isPrime(number)) {
      primeNumbers.push(number);
    }
  }
  return primeNumbers;
}

export function formatCompactNumber(number) {
  if (number < 1000) {
    return number;
  } else if (number >= 1000 && number < 1000000) {
    return (number / 1000).toFixed(1) + "K";
  } else if (number >= 1000000 && number < 1000000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number >= 1000000000 && number < 1000000000000) {
    return (number / 1000000000).toFixed(1) + "B";
  } else if (number >= 1000000000000) {
    return divideWithDecimal(number, 12) + "T";
  }
}
