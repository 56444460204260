import React from "react";
import "./CommonButton.scss";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";


const CommonButton = React.forwardRef(({
  title,
  className,
  icon,
  onClick,
  to,
  role,
  disabled,
  type,
  isLoading,
  text,
  children,
  fluid,
}, ref) => {

  return (
    <>
      {/* <button
        {...props}

      >
        {isLoading ? <Spinner /> : text}
        {children}
      </button> */}


      {(() => {
        switch (role) {

          case "link":
            return (
              <Link
                to={to}
                className={`btn-style ${fluid ? "w-100" : ""} ${className}`}
              >
                {icon && (
                  <>
                    <span>{icon}</span>&nbsp;
                  </>
                )}
                {title && <>{title}</>}
                {isLoading ? <Spinner /> : text}
                {children}
              </Link>
            );
          default:
            return (
              <button
                // disabled={isLoading && disabled}
                className={`btn-style ${fluid ? "w-100" : ""} ${className}`}
                onClick={onClick}
                disabled={disabled}
                type={type ? type : "button"}
                // data-data-tooltip-id="tool-tip"
                ref={ref}
              >
                {icon && (
                  <>
                    <span>{icon}</span> &nbsp;
                  </>
                )}
                {title && <>{title}</>}
                {/* {
                    loader && <Loader />
                  } */}
                {isLoading ? <Spinner /> : text}
                {children}
              </button>
            );
        }
      })()}
    </>
  );
})

export default CommonButton;
