import Web3 from "web3";
import { CHRIS_CONTRACT_ADDRESSS, RPC_URL } from "../constants";
import abi from "../abi/abi.json";
let web3Instance, icoInstance;

export const callWeb3 = (data = window.ethereum) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    web3Instance = new Web3(window.ethereum);
    resolve(web3Instance);
  });
};

export const createInstance = async (data = RPC_URL) => {
  const web3 = await callWeb3(data);
  /**CREATE CONTRACT INSTANCE WITH ABI */
  icoInstance = new web3.eth.Contract(abi, CHRIS_CONTRACT_ADDRESSS);
  return true;
};
createInstance();

/**SEND CONTRACT TYPE AND DYAMIC ADDRESS(OPTIONAL) FOR GET CONTRACT INSTANCE*/
const getContractInstance = async (provider = RPC_URL) => {
  return new Promise(async (resolve, reject) => {
    // eslint-disable-next-line no-case-declarations
    const dynamicInstance = web3Instance
      ? await new web3Instance.eth.Contract(abi, CHRIS_CONTRACT_ADDRESSS)
      : await createInstance(provider).then(async () => {
          return await new web3Instance.eth.Contract(
            abi,
            CHRIS_CONTRACT_ADDRESSS
          );
        });
    resolve(dynamicInstance);
  });
};

/**CALL CONTRACT GET METHODS. ALL PARAMS WILL BE DYNAMIC */
export const callGetMethod = async (method, data) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      /**GET SELECTED CONTRACT INSTANCE */
      const contract = await getContractInstance();
      if (contract.methods) {
        /**CALL GET METHOD */
        contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .call()
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

/**CALL CONTRACT SEND METHODS. ALL PARAMS WILL BE DYNAMIC */
export const callSendMethod = async (
  // provider,
  method,
  data,
  walletAddress,
  value
) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      /**CHECK WALLET IS CONNECTED */
      if (walletAddress === "") {
        reject(new Error("Please connect wallet"));
      }

      /**CREATE DATA FOR CALL SEND METHOD */
      const dataForSend = { from: walletAddress };

      /**CHECK IF WE NEED TO SEND VALUE IN SEND METHOD */
      if (value) {
        dataForSend.value = value;
      }
      /**GET SELECTED CONTRACT INSTANCE */
      const contract = await getContractInstance();

      if (contract.methods) {
        /**ESTIMATE GAS FOR TRANSACTION */
        const gasLimit = await contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .estimateGas(dataForSend);
        dataForSend.gasLimit = gasLimit;

        const web3 = await callWeb3();
        dataForSend.gasPrice = await web3.eth.getGasPrice();

        /**CALL SEND METHOD */
        contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .send(dataForSend)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};
