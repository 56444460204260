import axios from "axios";
import { useDispatch } from "react-redux";
import { API_HOST, APIURL } from "../constants";
import { formatUrl } from "./comman.service";
import store from "../redux/store";
import { resetUserDataSlice } from "../redux/userData/userData";

export const storeInstance = store;
axios.defaults.baseURL = API_HOST;

let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

/**AXIOS INTERCEPTOR */
axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return error;
  }
);

/**HANDLE AXIOS RESPONSE */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      // toaster.error("Server not responding. Please try again later.");
    } else {
      return manageErrorConnection(error);
    }

    const originalRequest = error.config;
    failedQueue.push(originalRequest);
    // CommonService.handleJWTExpiry(error)
    if (error.response.status === 403) {
      processQueue(error, null);
    }
  }
);
/**HANDLE AXIOS ERROR */
function manageErrorConnection(err) {
  if (
    err.response &&
    err.response.status >= 400 &&
    err.response.status <= 500
  ) {
    // toaster.error(err.response.data.msg);
    if (err.response.status === 401) {
      setTimeout(function () {
        store.dispatch(resetUserDataSlice());
      }, 1000);
    }
    return Promise.reject(err);
  } else if (err.code === "ECONNREFUSED") {
    // toaster.error('ECONNREFUSED');
    return "nevermind";
  } else {
    // toaster.error(err);
    return Promise.reject(err);
  }
}

export const callApiGetMethod = (
  method,
  parms = {},
  loading = true,
  showtoaster = true
) => {
  return (dispatch = useDispatch(), getState) =>
    new Promise(async (resolve, reject) => {
      /**SHOW LOADER */
      apiCallGet(APIURL[method], parms, showtoaster)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
};

/**CALL API'S SEND METHOD */
export const callApiPostMethod = (
  method,
  data = {},
  parms = {},
  showtoaster = false
) => {
  return (dispatch = useDispatch(), getState) =>
    new Promise(async (resolve, reject) => {
      /**CALL METHOD */
      apiCallPost(APIURL[method], data, parms, showtoaster)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
};

/**METHOD FOR CALL API */
export const apiCallPost = (url, data, params = {}, showtoaster = false) =>
  new Promise((resolve) => {
    axios
      .post(formatUrl(url, params), data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        resolve(null);
      });
  });

/**METHOD FOR SEND API */
export const apiCallGet = (url, params = {}, showtoaster = false) =>
  new Promise((resolve) => {
    axios
      .get(formatUrl(url, params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        resolve(null);
      });
  });
