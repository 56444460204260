import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./components/Routes/Routes.jsx";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./redux/store.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { EIP6963Connector, createWeb3Modal } from "@web3modal/wagmi/react";
import { walletConnectProvider } from "@web3modal/wagmi";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from "wagmi/connectors/injected";
import { Provider, useDispatch } from "react-redux";
import { useEffect } from "react";
import { browserName, isMobile } from "react-device-detect";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { holesky } from "viem/chains";
import useWalletAccount from "./helpers/useWalletAccount.js";
let chains = [holesky];
const WALLET_IDS = {
  // REFERENCE : https://explorer.walletconnect.com/
  METAMASK: "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  TRUSTWALLET:
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  COINBASE: "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
  SUBWALLET: "9ce87712b99b3eb57396cc8621db8900ac983c712236f48fb70ad28760be3f6a",
};
const projectId = "7e004e9cfa01b95768e4da0375b55f45";

function App() {
  const dispatch = useDispatch();

  const { publicClient } = configureChains(
    [holesky],
    [walletConnectProvider({ projectId }), publicProvider()]
  );

  let { ethereum, trustwallet } = window;

  // // For Metamask
  const { disconnectMessage: ethDisconnectMessage } = useWalletAccount({
    wallet: ethereum,
    walletType: "Wallet",
    dispatch,
    // toaster,
  });

  // For TrustWallet
  const { disconnectMessage: trustDisconnectMessage } = useWalletAccount({
    wallet: trustwallet,
    walletType: "Wallet",
    dispatch,
    // toaster,
  });

  useEffect(() => {}, [ethDisconnectMessage, trustDisconnectMessage]);

  // browser Name List :

  // "Device : Mobile IOS"
  // "Normal Browsers: Mobile Safari, Chrome, Brave"
  // "Dapp Browsers: for metamsk, trustwallet, coinbase wallet browsers - WebKit"

  // "Device : Mobile Android"
  // "Normal Browsers: Mobile Safari, Chrome"
  // "Dapp Browsers: for metamsk, trustwallet, coinbase wallet browsers - Chrome Webview"

  const metadata = {
    name: "Ethereum",
    description: "ethanet",
    url: "https://app.ethanet.com",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
  };

  let wagmiConfig;

  // If Mobile browser and not the dapp browser
  if (
    isMobile &&
    browserName?.toLowerCase() !== "chrome webview" &&
    isMobile &&
    browserName?.toLowerCase() !== "webkit"
  ) {
    wagmiConfig = createConfig({
      autoConnect: true,
      connectors: [
        new WalletConnectConnector({
          chains,
          options: { projectId, showQrModal: false, metadata },
        }),
        new EIP6963Connector({ chains }),
        new InjectedConnector({ chains, options: { shimDisconnect: true } }),
        new CoinbaseWalletConnector({
          chains,
          options: { appName: metadata.name },
        }),
      ],
      publicClient,
    });
  }

  // If Mobile Dapp Browser
  if (
    (isMobile && browserName?.toLowerCase() === "chrome webview") ||
    (isMobile && browserName?.toLowerCase() === "webkit")
  ) {
    wagmiConfig = createConfig({
      autoConnect: true,
      connectors: [
        new WalletConnectConnector({
          chains,
          options: { projectId, showQrModal: false, metadata },
        }),
        new EIP6963Connector({ chains }),
        new InjectedConnector({ chains, options: { shimDisconnect: true } }),
        new CoinbaseWalletConnector({
          chains,
          options: { appName: metadata.name },
        }),
      ],
      publicClient,
    });
  }

  // If Desktop Browser
  if (!isMobile) {
    wagmiConfig = createConfig({
      autoConnect: true,
      connectors: [
        new WalletConnectConnector({
          chains,
          options: { projectId, showQrModal: false, metadata },
        }),
        new EIP6963Connector({ chains }),
        // new InjectedConnector({ chains, options: { shimDisconnect: true } }), // This is the Browser wallet options (which is not required incase of desktop browser)
        new CoinbaseWalletConnector({
          chains,
          options: { appName: metadata.name },
        }),
      ],
      publicClient,
    });
  }

  let createModalFunction;

  // for mobile normal browsers
  if (
    isMobile &&
    browserName?.toLowerCase() !== "chrome webview" &&
    isMobile &&
    browserName?.toLowerCase() !== "webkit"
  ) {
    createModalFunction = async () =>
      createWeb3Modal({
        wagmiConfig,
        projectId,
        //defaultChain: holesky,
        chains,
        includeWalletIds: [
          WALLET_IDS?.METAMASK,
          // WALLET_IDS?.TRUSTWALLET,
          // WALLET_IDS?.COINBASE,
        ],
      });
  }

  // for mobile Dapp browsers
  if (
    (isMobile && browserName?.toLowerCase() === "chrome webview") ||
    (isMobile && browserName?.toLowerCase() === "webkit")
  ) {
    createModalFunction = async () =>
      createWeb3Modal({
        wagmiConfig,
        projectId,
        //defaultChain: holesky,
        chains,
        includeWalletIds: [WALLET_IDS?.TRUSTWALLET],
        // includeWalletIds: ["none"],
      });
  }

  // For Desktop Browsers
  else if (!isMobile) {
    createModalFunction = async () =>
      createWeb3Modal({
        wagmiConfig,
        projectId,
        //defaultChain: holesky,
        chains,
        // excludeWalletIds: [
        //   "9ce87712b99b3eb57396cc8621db8900ac983c712236f48fb70ad28760be3f6a"
        // ],
        includeWalletIds: [
          WALLET_IDS?.METAMASK,
          // WALLET_IDS?.TRUSTWALLET,
          // WALLET_IDS?.COINBASE,
        ],
      });
  }

  createModalFunction();

  return (
    <WagmiConfig config={wagmiConfig}>
      <RouterProvider router={createBrowserRouter(routes)}></RouterProvider>
    </WagmiConfig>
  );
}
export default App;
