import React from 'react'
import "./NotFound.scss"
import CommonButton from '../../../Common/CommonButton/CommonButton'

const NotFound = () => {
  return (
    <>
      <div className='notFound text-center'>
        {/* <img src={Img} alt="" /> */}
        <div>
          <h1 className="error">404</h1>
          <div className="mb-4">Ooops!!! The page you are looking for is not found</div>
          <CommonButton text="Back to home" role="link" to="/auth/history"/>
        </div>
      </div>
    </>
  )
}

export default NotFound
